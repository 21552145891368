.strandliebe-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.strandliebe {
			background-image: url(RESOURCE/img/banner-strandliebe.jpg);
		}
	}
}

.halbinsel-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.halbinsel {
			background-image: url(RESOURCE/img/banner-halbinsel.jpg);
		}
	}
}

.hund-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.hund {
			background-image: url(RESOURCE/img/banner-hund.jpg);

			@media (min-width: 1200px) {
				background-position: 0 -80px;
			}
		}
	}
}

.strandliebe-nor-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.strandliebe-nor {
			background-image: url(RESOURCE/img/banner-halbinsel.jpg);
		}
	}
}

.wattenmeer-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.wattenmeer {
			background-image: url(RESOURCE/img/banner-wattenmeer.jpg);
		}
	}
}

.reiseversi-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.reiseversi {
			background-image: url(RESOURCE/img/banner-halbinsel.jpg);
		}
	}
}

.freizeittipps-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.freizeittipps {
			background-image: url(RESOURCE/img/banner-halbinsel.jpg);
		}
	}
}

.impressum-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.impressum {
			background-image: url(RESOURCE/img/banner-halbinsel.jpg);
		}
	}
}

.about-us-v1 {
	margin-top: var(--page-margin-top);

	.inner-banner {
		&.about-us {
			background-image: url(RESOURCE/img/banner-halbinsel.jpg);
		}
	}
}
.download-item {
	position: relative;
	margin-bottom: 20px;
	background-color: var(--color-grey-light);
	display: inline-block;
	padding: 5px 15px 5px 25px;

	.download-item-icon {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
		left: 0;
	}

	.download-item-meta {
		color: gray;
		font-size: 13px;
	}
}
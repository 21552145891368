.regionen-v1 {
	margin-top: var(--page-margin-top);

	.img-bl {
		img {
			@media (max-width:992px) {
				height: 350px;
			}

			@media (max-width:550px) {
				height: 250px;
			}
		}
	}
}